import mapboxgl from '!mapbox-gl'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["marker"]

  coordinates() {
    return JSON.parse(this.data.get("geo"))
  }

  connect() {
    var me = this
    mapboxgl.accessToken = 'pk.eyJ1IjoiZm9ybXJhdXNjaCIsImEiOiJzUkxfYTA4In0.REy8tw_FMvmG7Tibs8T8mg';

    var centered = this.coordinates().features[0].geometry.coordinates
    var koblenz  = [7.5664013,50.39101]

    this.map_container = new mapboxgl.Map({
      container: this.element,
      attributionControl: false,
      // style: 'mapbox://styles/mapbox/satellite-v9'
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: centered, // starting position [lng, lat]
      zoom: 16,

    });
    this.map_container.scrollZoom.disable()
    this.map_container.addControl(new mapboxgl.NavigationControl())
    this.add_markers(this.map_container)
  }


  get_locations() {
    return {}
  }

  add_markers(to) {
    var me = this
    var geojson = this.coordinates()

    _.each(geojson.features, function(marker) {
      var el = document.createElement('div');
      el.className = 'simple_marker';
      el.dataset.action = "click->simple-map#marker_selected"
      el.dataset.target = "simple-map.marker"
      el.dataset.mapperId = marker.id
      el.title = marker.metafields.organisationseinheit

      new mapboxgl.Marker(el)
      .setLngLat(marker.geometry.coordinates)
      .addTo(to);
    });
  }

  resize() {
    this.map_container.resize()
  }

  disconnect() {
    this.map_container.remove()
  }
}



