import Cookies from "js-cookie"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["banner"]

  connect() {

  }

  disconnect() {
  }

  enable(e) {
    e.preventDefault()
    const isSecure = location.protocol === "https:";
    Cookies.set("cookie_eu_consented", true, { path: "/", expires: 365, secure: isSecure });
    Cookies.set("cookie_eu_consented", true, { path: "/", expires: 365, secure: isSecure })
    this.hide_banner()
    location.reload()
  }

  disable(e) {
    if (e != undefined) {
      e.preventDefault()
    }
    const isSecure = location.protocol === "https:";
    Cookies.set("ga-disable-UA-136673106-1", true, { path: "/", expires: 365, secure: isSecure })
    Cookies.set("cookie_eu_consented", false, { path: "/", expires: 365, secure: isSecure })
    this.hide_banner()
    location.reload()
  }

  hide_banner() {
    if (this.hasBannerTarget) {
      this.bannerTarget.classList.add("hidden")
    }
  }
}

