import Swiper, { Navigation, Mousewheel, Keyboard, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Mousewheel, Keyboard, Pagination, Autoplay]);
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.sliderContainer = this.hasContainerTarget ? this.containerTarget : this.element;
    this.swiper = new Swiper(this.sliderContainer, {
      spaceBetween: 0,
      simulateTouch: true,
      width: null,
      navigation: {
        nextEl: this.nextButtonTargets,
        prevEl: this.prevButtonTargets,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        forceToAxis: true,
        thresholdDelta: 30,
        thresholdTime: 300
      },
      autoplay: {
        delay: 4000
      }
    });
    // Toggle autoplay depending on visibility
    this.swiper.autoplay.stop()
    this.observer = new IntersectionObserver(this.onViewIntersection.bind(this), {});
    this.observer.observe(this.sliderContainer);
  }

  onViewIntersection(entries) {
    const entry = entries[0];
    if (entry.intersectionRatio > 0) // In viewport
      this.swiper?.autoplay.start(); // or continueAutoplay()
    else  // Out of viewport
      this.swiper?.autoplay.stop();
  }

  pauseAutoplay() {
    this.swiper.autoplay.stop();
  }

  continueAutoplay() {
    if(!this.autoplayDisabled)
      this.swiper.autoplay.start();
  }

  disableAutoplay() {
    this.autoplayDisabled = true
    this.swiper.autoplay.stop();
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}
