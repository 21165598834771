import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "tabs", "root", "link"]

  connect() {
    var me = this
    var first = this.first_tab_name()
    this.show(first)
    var anchor = window.location.hash.substr(1)
    var tab = this.has_tab(anchor)
    if (tab != undefined) {
      this.show(anchor)
      // tab.scrollIntoView(false)
      // setTimeout(function() {
      //   console.log("-------------------- offset")
      //   var y = me.offset(me.rootTarget).top
      //   console.log(y)
      // }, 3000)
    }
  }

  offset(el) {
    var rect = el.getBoundingClientRect()
    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  first_tab_name() {
    var link = _.first(this.linkTargets)
    var path = link.getAttribute("href")
    var link_name = _.last(path.split("#"))
    return link_name
  }

  has_tab(name) {
    var tab = _.find(this.linkTargets, function(link) {
      var path = link.getAttribute("href")
      var link_name = _.last(path.split("#"))
      return link_name == name
    })
    return tab
  }

  show(name) {
    var inactive_items = this.contentTarget.getElementsByClassName("content-selector_item")
    var active_items   = this.contentTarget.getElementsByClassName(name)

    _.each(inactive_items, function(inactive) { inactive.classList.remove("active") })
    _.each(active_items, function(active) { active.classList.add("active") })

    var links = this.linkTargets
    _.each(links, function(link) { link.classList.remove("active") })

    var active_link = _.filter(links, function(link) {
      var path = link.getAttribute("href")
      var link_name = _.last(path.split("#"))

      return name == link_name
    })

    _.each(active_link, function(link) { link.classList.add("active") })
    var event = new Event("content:show:" + name)
    document.dispatchEvent(event)
  }

  activate(e) {
    e.preventDefault()
    var path = e.target.getAttribute("href")
    var name = _.last(path.split("#"))

    window.location = "#" + name

    this.show(name)
  }
}
