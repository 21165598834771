import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "burger", "overlay", "page", "dropdown", "main" ]

  connect() {
    if (window.last_navigation_event != undefined) {
      this.hide_until_reentry = true
    } else {
      this.hide_until_reentry = false
    }

    this.hide = true
  }

  transitionend() {
    if (this.overlayTarget.classList.contains("visible")) {
      this.pageTarget.classList.add("no_scroll")
    } else {
      this.pageTarget.classList.remove("no_scroll")
    }
  }

  reenable(e) {
    this.hide_until_reentry = false
  }

  show(e) {
    e.preventDefault()
    this.overlayTarget.classList.add("visible")
  }

  hide_overlay(e) {
    e.preventDefault()
    this.overlayTarget.classList.remove("visible")
  }

  activate2(e) {
    if (this.hide_until_reentry) {
      return
    }

    this.hide = false

  }

  activate(e) {
    if (this.hide_until_reentry) {
      return false
    }

    this.hide = false

    var name = e.target.classList[0]
    window.last_navigation_event = e

    var items = this.dropdownTarget.getElementsByClassName("navigation--dropdown-item")
    var subnav = this.dropdownTarget.getElementsByClassName(name)

    this.dropdownTarget.classList.add("visible")
    this.mainTarget.classList.add("header-active")

    _.each(items, function(n)  { n.classList.remove("visible") })
    _.each(subnav, function(n) { n.classList.add("visible")    })
  }

  deactivate(e) {
    window.last_navigation_event = undefined
    this.hide = true
    var me = this
    setTimeout(function() {
      if (me.hide == true) {
        me.dropdownTarget.classList.remove("visible")
        setTimeout(function() {
          if (me.hide == true) {
            me.mainTarget.classList.remove("header-active")
          }
        }, 100)
      }
    }, 300)
  }
}

