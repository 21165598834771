import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "root", "list", "container" ]

  hello(e) {
    this.show()
  }

  connect() {
    var id = this.data.get("id")
    this.binding = this.hello.bind(this)
    document.addEventListener(id, this.binding, false)
  }

  disconnect() {
    var id = this.data.get("id")
    document.removeEventListener(id, this.binding, false)
  }

  activate(e) {
    e.preventDefault()
    this.show()
  }

  show() {
    var me = this
    this.containerTarget.classList.add("visible")
    setTimeout(function() {
      me.active = true
    }, 200)
  }

  deactivate(e) {
    if (this.active == true && e.target != this.rootTarget) {
      this.containerTarget.classList.remove("visible")
      this.active = false
    }
  }

}